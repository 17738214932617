import React, { useEffect } from "react";
import { useHistory, useNavigate } from "react-router";
import { user } from "../../atom/userAtom";
import { useRecoilValue } from "recoil";
import axios from "axios";
import { useState } from "react";
import { authState } from "../../atom/authAtom";
import hero_bg from "../../assets/background-img/hero-bg.webp";
import { Link } from "react-router-dom";
import loggedInImage from '../../assets/background-img/loggedIn.webp'
function Hero() {
  const history = useNavigate();
  const userData = useRecoilValue(user);
  const auth = useRecoilValue(authState);

  const handleClick = () => {
    history("/enrollment");
  };
  return (
    <div className="mt-[100px] w-screen h-full overflow-hidden">
      {auth && auth?.length > 0 ? (
        <div>
          <div className=" h-[40vh] flex items-center flex-col gap-3 justify-center bg-learning ">
            <h2 className="text-center">Welcome, {userData?.fullName}</h2>
            <Link to='/enrollment' className="pri_btn">Enroll Now</Link>
          </div>
          <div className="h-full justify-center items-center  mt-10">
            <img src={loggedInImage} alt="" className="h-[40vh] w-fit mx-auto object-cover" />
          </div>
        </div>
      ) : (
        <div className="main grid md:grid-cols-2 items-center">
          <div className="lg:w-[90%] grid gap-5 order-2 md:order-1">
            <h1 className="text-4xl lg:text-7xl bold ">
              Learn coding Online the fun way
            </h1>
            <div className="">
              <p>Coding and other tech skills in an interactive manner</p>
              <p>Friendly and professional tutors</p>
              <p>Gain mastery in your skills</p>
            </div>
            <Link to='/register' className="bg-[var(--primary)] w-fit rounded-full px-6 py-2">
              Join Now
            </Link>
          </div>
          <div className="w-full order-1 md:order-2">
            <img src={hero_bg} alt="" className="w-full  object-contain" />
          </div>
        </div>
      )}
    </div>
  );
}

export default Hero;
