import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { getMsdkSignature } from "../service/api";
import { useRecoilValue } from "recoil";
import { user } from "../atom/userAtom";

export default function ZoomMeeting() {
  const queryParams = new URLSearchParams(window.location.search);
  const meetingNumber = queryParams.get("meetingId");
  const password = queryParams.get("password");
  const curerentUser = useRecoilValue(user);

  useEffect(() => {
    const importZoom = async () => {
      const { ZoomMtg } = await import("@zoom/meetingsdk");
      const getSignature = async () => {
        try {
          const payload = {
            meetingNumber,
          };
          const response = await getMsdkSignature(payload);
          const signature = response.signature;
          initializeZoom(signature);
        } catch (error) {
          console.error("Failed to get Zoom signature:", error);
        }
      };
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareWebSDK();
      getSignature();

    

      const initializeZoom = async (signature) => {
        document.getElementById("zmmtg-root").style.display = "block";

        ZoomMtg.init({
          leaveUrl: "https://www.chariscodingclub.com/my-learning",
          patchJsMedia: true,
          leaveOnPageUnload: true,
          success: (success) => {
            console.log(success);

            ZoomMtg.join({
              signature: signature,
              sdkKey: "gK6_ma6ATvdS0o54puWug",
              meetingNumber: meetingNumber,
              passWord: password,
              userName: curerentUser?.fullName,
              userEmail: curerentUser?.email,
              success: (success) => {
                console.log(success);
              },
              error: (error) => {
                console.log(error);
              },
            });
          },
          error: (error) => {
            console.log(error);
          },
        });
      };
    };

    importZoom();
  }, [meetingNumber]);
  return <div id="meetingSDKElement"></div>;
}
