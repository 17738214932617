import React, { useEffect, useState } from "react";
import Hero from "../components/hero/Hero";
import Roadmap from "../components/roadmap/Roadmap";
import Category from "../components/category/Category";

import EntrustChild from "../components/entrust-child/EntrustChild";
import Projects from "../components/projects/Projects";
import Review from "../components/review/Review";
import videoBg from "../assets/background-img/video-bg.webp";
import CourseCard from "../components/course-card/CourseCard";
import SkeletonLoader from "../components/skeleton/Skeleton";
import { getAllCourses } from "../service/api";
import { Link } from "react-router-dom";

function Home() {
  const [tab, setTab] = useState("scratch");
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);

  const allCourses = async () => {
    setLoading(true);
    await getAllCourses().then((res) => {
      setCourses(res?.data?.data?.slice(0, 3));
      setLoading(false);
    });
  };
  useEffect(() => {
    allCourses();
  }, []);

  const video =
    " https://res.cloudinary.com/dzrrdkd7i/video/upload/v1716981941/child-video_ybvy1r.mp4";
  return (
    <div className="home__main">
      <Hero />

      <div className="w-screen h-full lg:h-[70vh] overflow-hidden py-10">
        <video
          src={video}
          muted
          autoPlay
          loop
          poster={videoBg}
          className="w-full h-full object-cover"
        ></video>
      </div>

      <Roadmap />
      <Category
        children={
          <div className=" flex flex-col pb-10 w-full">
            <div className="main grid md:grid-cols-2 lg:grid-cols-3 gap-4 pb-10 w-full">
              {loading ? (
                <>
                  {[1, 2, 3].map((res, key) => (
                    <SkeletonLoader key={key} />
                  ))}
                </>
              ) : (
                ""
              )}
              {courses?.map((res, key) => (
                <CourseCard
                  key={key}
                  title={res?.courseTitle}
                  localPrice={res?.localPrice}
                  internationalPrice={res?.internationalPrice}
                  duration={res?.duration}
                  courseImg={res?.image}
                  age={res?.age}
                  prequisite={res?.prequisite}
                  id={res?._id}
                  tag={res?.tag}
                  learnMore={true}p
                />
              ))}
            </div>
            <Link
              to="/courses"
              className="bg-[var(--primary)] w-fit rounded-full px-6 py-2 mx-auto"
            >
              Explore All Courses
            </Link>
          </div>
        }
      />

      <EntrustChild />
      <Projects />
      <Review />
    </div>
  );
}

export default Home;
