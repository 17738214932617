import React, { useEffect, useState } from "react";
import CourseCard from "../components/course-card/CourseCard";
import { getCourseById } from "../service/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import SkeletonLoader from "../components/skeleton/Skeleton";

export default function CourseDetail() {
  const [course, setCourse] = useState({});
  const [loading, setLoading] = useState(true);
  const param = useParams();
  const getCourse = async () => {
    await getCourseById(param.id).then((course) => {
      setCourse(course.data.data);
      setLoading(false);
    });
  };
  useEffect(() => {
    getCourse();
  }, []);

  return (
    <div className="mt-[100px] w-screen h-full relative">
      <div className="main  h-full pb-10 lg:py-20 place-items-center">
        <>
          {loading ? (
            <div className="grid md:grid-cols-2 gap-10 ">
              <div className="flex flex-col gap-2 justify-center">
                <Skeleton className="!h-8"></Skeleton>
                <Skeleton></Skeleton>
                <Skeleton className="!h-[200px]"></Skeleton>
              </div>
              <SkeletonLoader></SkeletonLoader>
            </div>
          ) : (
            <div className="h-full grid lg:grid-cols-2 gap-10 lg:gap-20">
              <div className="left">
                <h2 className="title pt-20">{course?.courseTitle}</h2>
                <div className="text pt-4">{course?.fullDescription}</div>
              </div>
              <div className="right">
                <CourseCard
                  title={course?.courseTitle}
                  localPrice={course?.localPrice}
                  internationalPrice={course?.internationalPrice}
                  duration={course?.duration}
                  courseImg={course?.image}
                  age={course?.age}
                  prequisite={course?.prequisite}
                  id={course?._id}
                  tag={course?.tag}
                />
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  );
}
