import React from "react";
import HeroTwo from "../components/heroTwo/HeroTwo";
import faq_bg from "../assets/background-img/faq-bg.png";
import pattern from "../assets/patterns/about-pattern.svg";
import pattern2 from "../assets/patterns/about-pattern2.svg";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
function Faq() {
  const questions = [
    {
      faq: "How can my child attend a one-on-one class?",
      ans: "Kindly fill the contact us form and we will contact you for detailed arrangement",
    },
    {
      faq: "What happens if my child is unable to attend a class?",
      ans: "Kindly contact us as soon as you know your child cannot make a class. If your child is unavoidably absent, a catchup class will be arranged.",
    },
    {
      faq: "What happens if I am on the waiting list?",
      ans: "If you are on the waiting list, it means the class is fully booked. We will contact you with details of when the next class will commence.",
    },
    {
      faq: "Are the timings of the classes fixed?",
      ans: "The exact time each course will be scheduled to take place weekly is subject to change with prior notice.",
    },
    {
      faq: "How long does each class last?",
      ans: "Each class would typically last for 2 hours with a small break of 10-15 minutes in between.",
    },
    {
      faq: "What does my child need to attend this course?",
      ans: `1. Stable and high speed internet connection
           2. A laptop /desktop (To enable easy navigation for hands-on practice  during the class)  
           3. Commitment of parents to assist younger children in submitting their assignments.`,
    },
    {
      faq: "How are classes conducted?",
      ans: "Our classes are conducted online through zoom. The children are encouraged to think independently while learning and they are able to collaborate with other students. This enhances peer-to-peer learning. Children are also given assignments to do and submit online. The progress of children is accessed regularly using different assessment methods.",
    },
    {
      faq: "How do I know which scratch class is suitable for my child?",
      ans: "If your child has never learnt about programming and is  between 6+ to 12 years old, the beginners class is where to begin. However, if your child has some basic programming experience in scratch, intermediate class will be right for your child. Children ages 12 to 16 who have been programming with scratch and seek to gain advanced knowledge in the use of scratch can sign up for the advanced scratch class.",
    },
    {
      faq: `My child has attended the beginners and advanced classes. What's next?`,
      ans: "Scratch has numerous features that can be explored. Encourage your child to continue exploring the power of scratch by creating a wide range of projects. Scratch is designed especially for young people between the ages of 6 and 16. Let them enjoy the many benefits scratch has to offer. Children above the age of 9 who are interested in improving their programming skills further can enroll for other coding courses such as python and Web development",
    },
    {
      faq: "What is your refund policy?",
      ans: "All refund requests should be sent by email to training@chariscodingclub.com If a request for refund is made up to 2 weeks before the start date of the class, the amount paid for the class will be refunded in full (Administrative fee of 5% applies) If a request for refund is made less than 2 weeks before the classes commence or if the student does not show up in class, there will be no refund.",
    },

    {
      faq: "Do you organize replacement classes?",
      ans: `If you are not eligible for a refund, we are more than happy to reserve your child's slot for a future program of similar cost. In a situation whereby you choose a program which cost more, the balance will have to be paid.`,
    },
    {
      faq: "Do you have discounts on referral?",
      ans: "Yes, we provide 3% discount on referral applicable for up to a maximum of 3 referrals. Please contact training@chariscodingclub.com to get a coupon for any referral made. Kindly note that discount will only be given if payment has been made by people that were referred.",
    },
  ];

  return (
    <div>
      <HeroTwo head="Frequently Asked Questions" />
      <div className="faq__main mt-[50px] w-screen h-full pb-10 ">
        <div className="main h-full grid  md:grid-cols-2 gap-8 md:gap-6 place-items-center pb-10">
          <div className=" grid gap-5 order-2 md:order-1 ">
            <h1 className="lg:w-[80%]">Frequently Asked Questions</h1>
          </div>
          <div className="h-full order-1 md:order-2">
            <img src={faq_bg} alt="" className="h-fit object-contain" />
          </div>
          <div className="hidden lg:block  absolute bottom-0 left-0">
            <img src={pattern} alt="" />
          </div>
          <div className="absolute right-0">
            <img src={pattern2} alt="" />
          </div>
        </div>
        <div className="faq__left  main">
          <div className=" lg:w-[80%] ml-auto ">
            <Accordion allowZeroExpanded>
              {questions.map((item) => (
                <AccordionItem key={questions.indexOf(item)}>
                  <AccordionItemHeading>
                    <AccordionItemButton>{item.faq}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>{item.ans}</p>
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
