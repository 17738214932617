
import React from "react";
import { useRecoilValue } from "recoil";
import { authState } from "../atom/authAtom";
import ScrollToTop from "../pages/scrollToTop";
import Header from "../components/header/Header";
import { Navigate, Outlet } from "react-router-dom";

export default function MeetingLayout() {
  const auth = useRecoilValue(authState);

  return (
    <div>
    {auth && auth.length > 0 ? (
      <>
        <Outlet />
        <ScrollToTop />
      </>
    ) : (
      <Navigate to="/login" />
    )}
  </div>
  )
}


