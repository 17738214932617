import React from "react";
import reviewPath from "../../assets/patterns/review-path.svg";
import Slider from "react-slick";
import data from "../../data/slider.json";

export default function Review() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
    ]
  };
  return (
    <div className="mt-[80px] w-screen h-full pb-10 ">
      <div className=" grid gap-2 text-center items-center ">
        <div className="relative">
          <h2 className="text-2xl lg:text-4xl bold mx-auto mb-3">
            Reviews from parents
          </h2>
          <div className="h-[40px] absolute right-[28%] top-[25%]">
            <img src={reviewPath} alt="" className="h-full object-contain" />
          </div>
          <p className="mx-auto ">
            Charis coding club is loved by thousands of parents worldwide
          </p>
        </div>
        <div className="w-full ">
          {/* {review.map((res) => (
                <div className="bg-[var(--secondary)] lg:w-[40%] rounded-lg p-4 text-sm  gap-4 mb-[20px] text-left mx-auto">
                        <p className='text-justify'>{res.text}</p>
                        <h4 className='semiBold'>
                            {res.name}
                        </h4>
                        <p>{res.location}</p>

                    </div>
            ))} */}
        </div>
      </div>
      <div className="main">
        <div className="h-full">
          <Slider {...settings}>
            {data.map((mydata, key) => (
              <div className=" rounded-lg p-2  space-x-2 relative" key={key}>
                <div className="carousel__name h-[250px] p-7 bg-[var(--secondary)] rounded-lg">
                  <p className="text-[15px]">{mydata.review}</p>
                  <div className="absolute bottom-5">
                    <h4 className=" text-xl leading-3 semiBold">{mydata.name}</h4>
                    <p className="text-sm">{mydata.city}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
