import React from "react";
import { Skeleton } from "primereact/skeleton";

export default function SkeletonLoader() {
  return (
    <div className="p-5 bg-white rounded-xl shadow-lg h-[480px]">
      <Skeleton className="!h-[45%]"></Skeleton>
      <Skeleton className="!mt-4 !h-[30px] !w-[80%]"></Skeleton>
      <div className="grid gap-2 mt-4">
        <Skeleton className="!w-[90%]"></Skeleton>
        <Skeleton className="!w-[70%]"></Skeleton>
        <Skeleton className=""></Skeleton>
        <Skeleton className=""></Skeleton>
      </div>
      <Skeleton className="!h-[45px] !rounded-full mt-6"></Skeleton>
    </div>
  );
}
