import React, { useEffect, useState } from "react";
import { Avatar } from "primereact/avatar";
import { useRecoilState, useRecoilValue } from "recoil";
import { user } from "../atom/userAtom";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { toast } from "react-toastify";
import validator from "validator";
import { authState } from "../atom/authAtom";

import pattern from "../assets/patterns/about-pattern.svg";
import pattern2 from "../assets/patterns/about-pattern2.svg";
import { getProfile } from "../service/api";

function Profile() {
  const [open, setOpen] = useState(false);
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState(null);
  const [phoneError, setPhoneError] = useState("");
  const [userData, setUserData] = useRecoilState(user);
  const userAuth = useRecoilValue(authState);
  const editProfile = () => {
    setOpen(!open);
    setPhone(userData?.phoneNumber);
    setFullName(userData?.fullName);
  };

  const saveProfile = async () => {
    const payload = {
      fullName,
      phone,
      email: userData?.email,
    };
  };

  const validatePhoneNumber = (number) => {
    const isValidPhoneNumber = validator.isMobilePhone(number);
    if (isValidPhoneNumber) {
      setPhoneError(false);
    } else {
      setPhoneError(true);
    }
    return isValidPhoneNumber;
  };

  function SaveProfileButton() {
    if (phone && fullName && !phoneError) {
      return (
        <button className="pri_btn w-fit ml-0" onClick={saveProfile}>
          Save
        </button>
      );
    } else {
      return (
        <button className="pri_btn w-fit ml-0 !bg-gray-300" disabled>
          Save
        </button>
      );
    }
  }

  useEffect(()=> {
    getProfile().then(({ data }) => {
      setUserData(data.data);
    });
  }, [])

  return (
    <div className="profile mt-[50px] w-screen h-full">
      <div className="main h-full grid pt-12 gap-8 md:gap-6 place-items-center">
        <h2 className=" font-black  ">My Profile</h2>
        <div className="h-[40vh] order-1 md:order-2 profile-bg lg:w-[80%] rounded-3xl w-full">
          {/* <img src={profile_bg} alt="" className="h-fit  object-contain" /> */}
          <div className="main grid lg:grid-cols-2 items-center h-full">
            <div className="text-[var(--green)] grid">
              <h1>{userData?.referralPoint}</h1>
              <h3 className="text-xl">Your Referral Points</h3>
            </div>
            <div className="w-fit lg:ml-auto">
              <Avatar
                label={userData?.fullName.split("")[0].toUpperCase()}
                shape="circle"
                className=" !h-[80px] !w-[80px] lg:!h-[150px] lg:!w-[150px] rounded-full lg:text-4xl bg-gray-400"
              />
            </div>
          </div>
        </div>
        <div className="absolute bottom-[25%] left-0 md:bottom-[35%]">
          <img src={pattern} alt="" className="w-[70%]" />
        </div>
        <div className="absolute right-0 top-[18%] lg:top-[40%] md:top-[10%]">
          <img src={pattern2} alt="" className="w-[70%]" />
        </div>
      </div>
      <div className="profile__body main h-full grid gap-8 md:gap-6 place-items-center pb-10">
        {open ? (
          <div className="formBody w-full md:w-[70%] grid grid-cols-1 md:grid-cols-2 mt-5 mb-5 gap-5">
            <span className="p-float-label">
              <InputText
                id="fullName"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
              <label htmlFor="email">Full Name</label>
            </span>
            <span className="p-float-label">
              <InputText
                id="phone"
                value={phone}
                onChange={(e) => {
                  validatePhoneNumber(e.target.value);
                  setPhone(e.target.value);
                }}
                required
              />
              <label htmlFor="phone">Phone Number</label>
              {phoneError && (
                <small style={{ color: "red" }}>Phone Number is Required</small>
              )}
            </span>
          </div>
        ) : (
          <div className="formBody w-full md:w-[70%] grid grid-cols-1 md:grid-cols-2 mt-5 mb-5 gap-5">
            <div className="formgroup">
              <label className="font-bold">Full Name</label>
              <div className=" p-3 bg-gray-50 w-full border-gray-300 border rounded">
                {userData?.fullName}
              </div>
            </div>
            <div className="formgroup">
              <label className="font-bold">Email</label>
              <div className=" p-3 bg-gray-50 w-full border-gray-300 border rounded">
                {userData?.email}
              </div>
            </div>
            <div className="formgroup">
              <label className="font-bold">Phone Number</label>
              <div className=" p-3 bg-gray-50 w-full border-gray-300 border rounded">
                {userData?.phoneNumber}
              </div>
            </div>
            <div className="formgroup">
              <label className="font-bold">Referral Code</label>
              <div className=" p-3 bg-gray-50 w-full border-gray-300 border rounded">
                {userData?.referralCode}
              </div>
            </div>

            <div className="formgroup">
              <label className="font-bold">Referral Points</label>
              <div className=" p-3 bg-gray-50 w-full border-gray-300 border rounded">
                {userData?.referralPoint}
              </div>
            </div>
          </div>
        )}
        <div className=" flex items-center gap-4">
          <button className="pri_btn w-fit ml-auto" onClick={editProfile}>
            {open ? "Cancel" : "Edit Profile"}
          </button>
          {open ? <SaveProfileButton /> : ""}
        </div>
      </div>
    </div>
  );
}

export default Profile;
