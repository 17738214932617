import React, { useEffect, useState } from "react";
import courses_bg from "../assets/background-img/courses-bg.webp";
import tutor_img from "../assets/background-img/tutor-img.webp";
import aboutPattern from "../assets/patterns/about-pattern.svg";
import aboutPattern2 from "../assets/patterns/about-pattern2.svg";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "../atom/authAtom";
import CourseCard from "../components/course-card/CourseCard";
import { getAllCourses } from "../service/api";
import SkeletonLoader from "../components/skeleton/Skeleton";

function Course() {
  const auth = useRecoilValue(authState);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);

  const allCourses = async () => {
    setLoading(true);
    const result = await getAllCourses()
    if(result){
      setLoading(false);
      setCourses(result?.data?.data);
    }  

  };

  useEffect(() => {
    allCourses();
  }, []);

  return (
    <div className="mt-[100px] w-screen h-full relative">
      <div className="main h-full grid  md:grid-cols-2 gap-8 md:gap-6 place-items-center">
        <div className=" grid gap-5 order-2 md:order-1">
          <h1 className="">Quality Learning Through Our Lessons</h1>
          <p>
            Learn about Python, Scratch, 3D Animation, HTML, CSS * Javascript
            and much more
          </p>
        </div>
        <div className="h-full order-1 md:order-2">
          <img src={courses_bg} alt="" className="h-fit  object-contain" />
        </div>
      </div>
        <h2 className="text-center py-4">Our Courses</h2>
      <div className="main grid md:grid-cols-2 lg:grid-cols-3 gap-4 pb-10">
        {loading ? (
          <>
            {[1, 2, 3].map((res, key) => (
              <SkeletonLoader key={key} />
            ))}
          </>
        ) : (
          ""
        )}
        {courses?.map((res) => (
          <CourseCard
            title={res?.courseTitle}
            localPrice={res?.localPrice}
            internationalPrice={res?.internationalPrice}
            duration={res?.duration}
            courseImg={res?.image}
            age={res?.age}
            prequisite={res?.prequisite}
            id={res?._id}
            tag={res?.tag}
            learnMore={true}
          />
        ))}
      </div>
      <div className=" grid gap-2 text-center h-full py-10 lg:py-2 place-items-center ">
        <h2 className=" ">Tutoring the fun way</h2>
        <p className="">
          Get friendly and professional tutors for your kids on our platform
        </p>
        <div className="py-5 w-full lg:w-[80%] mx-auto">
          <img src={tutor_img} alt="" className="h-full object-contain" />
          <div className="absolute top-[24%] left-0">
            <img src={aboutPattern} alt="" />
          </div>
      
        </div>
        {auth && auth.length > 0 ? (
          ""
        ) : (
          <Link to="/register" className="pri_btn w-fit mt-[-10%]">
            Join Now
          </Link>
        )}
      </div>
    </div>
  );
}

export default Course;
