import React from 'react';
import HeroTwo from '../components/heroTwo/HeroTwo';
import { Link } from 'react-router-dom';
import blogData from "../data/blog.json";
import blog from "../assets/background-img/blog1-img.png"

export default function Blog() {
    return (
        <div className='blog__container'>
            <HeroTwo 
            head='Blog'
            bgposition='bottom'
            bgsize='cover'
            />
            <div className="blog__main mt-[100px]  w-screen h-full">
                <div className="main h-full  place-items-center ">
                   <h1 className='text-center'>
                   The Charis Blog
                   </h1> 
                   <div className="grid gap-5 lg:gap-10 mt-[10px]">
                    {blogData.map((res) => (
                        <div className="grid md:grid-cols-2 gap-4 md:gap-6 place-items-center">
                            <div className="h-full">
                                <img src={blog} alt="" className='h-fit object-contain'/>
                            </div>
                            <div className="grid gap-4">
                                <div className="grid gap-4 lg:w-[96%]">
                                    <h3 className="semiBold text-2xl lg:w-[70%]">{res.title}</h3>
                                    <p>{res.text}</p>
                                    <p>{res.text_2}</p>
                                </div>
                                <button className="pri_btn w-fit" >
                                   <Link to={res.link}>
                                   Read more
                                    </Link> 
                                </button>
                            </div>
                        </div>
                    
                    ))}
                   </div>
                </div>
            </div>
        </div>
    )
}
