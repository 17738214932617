import React from "react";
import contactImg from "../assets/background-img/contact-img.png";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { contactValidation } from "../service/validation";
import { contact } from "../service/api";
import { toast } from "react-toastify";
import { user } from "../atom/userAtom";
import { useRecoilValue } from "recoil";

function Contact() {
  const userData = useRecoilValue(user);

  const onSubmit = async (values) => {
    try {
      await contact(values).then(({ data }) => {
        resetForm();
        toast.success("Message sent successful");
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const initialValues = {
    email: userData?.email ?? "",
    name: userData?.fullName ?? "",
    message: "",
  };

  const {
    values,
    errors,
    isValid,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    validateOnMount: true,
    initialValues: initialValues,
    validationSchema: contactValidation,
    onSubmit,
  });

  return (
    <div className="">
      <div head="Contact Us" />
      <div className="main__blog mt-[100px]  w-screen h-full">
        <div className="main h-full grid md:grid-cols-[2fr,9fr] gap-2 py-10  ">
          <div className="">
            <div className="h-full">
              <img
                src={contactImg}
                alt=""
                className=" h-[100px] lg:h-fit object-contain"
              />
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <h1 className="lg:w-[50%] pb-10">Get in touch with us:</h1>
            <div className="grid w-full gap-4 md:grid-cols-2">
              <div className="">
                <span className="p-float-label">
                  <InputText
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full"
                  />
                  <label htmlFor="email">Full Name</label>
                </span>
                {errors.name && (
                  <small className=" text-red-500">{errors.name}</small>
                )}
              </div>
              <div className="">
                <span className="p-float-label">
                  <InputText
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full"
                  />
                  <label htmlFor="email">Email</label>
                </span>
                {errors.email && (
                  <small className=" text-red-500">{errors.email}</small>
                )}
              </div>
            </div>
            <div className="">
              <span className="p-float-label mt-4">
                <textarea
                  rows="10"
                  cols="50"
                  className="w-full border p-3 resize-none"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></textarea>
                <label htmlFor="message">Message</label>
              </span>
              {errors.message && (
                <small className=" text-red-500">{errors.message}</small>
              )}
            </div>
            <button className="pri_btn" disabled={isSubmitting || !isValid}>
              {isSubmitting ? <i className="pi pi-spin pi-spinner"> </i> : ""}
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
