import { createBrowserRouter } from "react-router-dom";
import DefaultLayout from "./layouts/DefaultLayout";
import Home from "./pages/Home";
import About from "./pages/About";
import Faq from "./pages/Faq";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import Course from "./pages/Course";
import Contact from "./pages/Contact";
import Enrollment from "./pages/Enrollment";
import Payment from "./pages/Payment";
import Profile from "./pages/Profile";
import MyPayments from "./pages/MyPayments";
import MyLearning from "./pages/MyLearning";
import GuestLayout from "./layouts/GuestLayout";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Register from "./pages/Register";
import Otp from "./pages/Otp";
import Policy from "./pages/Policy";
import ErrorPage from "./pages/ErrorPage";
import LoggedInLayout from "./layouts/LoggedInLayout";
import CourseDetail from "./pages/CourseDetail";
import ZoomMeeting from "./pages/ZoomMeeting";
import MeetingLayout from "./layouts/MeetingLayout";

const router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/faq",
        element: <Faq />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/blog-detail/:id",
        element: <BlogDetails />,
      },
      {
        path: "/courses",
        element: <Course />,
      },
      {
        path: "/course-detail/:id",
        element: <CourseDetail />,
      },
   
      {
        path: "/enrollment",
        element: <Enrollment />,
      },
      {
        path: "/enrollment/payment",
        element: <Payment />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
     
      {
        path: "/privacy-policy",
        element: <Policy />,
      },
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
  {
    path: "/",
    element: <LoggedInLayout />,
    children: [
      {
        path: "/Profile",
        element: <Profile />,
      },
      {
        path: "/my-payments",
        element: <MyPayments />,
      },
      {
        path: "/my-learning",
        element: <MyLearning />,
      },
    ],
  },
  {
    path: "/",
    element: <MeetingLayout />,
    children: [
      {
        path: "/zoom",
        element: <ZoomMeeting />,
      },
    ],
  },
  {
    path: "/",
    element: <GuestLayout />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/otp",
        element: <Otp />,
      },
    ],
  },
]);

export default router;
