import { Outlet } from "react-router-dom";
import Header from "../components/header/Header";
import Chat from "../components/chat/Chat";
import ScrollToTop from "../pages/scrollToTop";
import Footer from "../components/footer/Footer";
export default function DefaultLayout() {
  

  return (
    <div>
      <ScrollToTop />
      <Chat />
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}
