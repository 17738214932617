import moment from "moment";
import * as yup from "yup";

const registerUserValidation = yup.object().shape({
  fullName: yup.string().required("Full Name is Required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email is Required"),
  phoneNumber: yup.number().required("phone number is required"),
  password: yup.string().min(5).required("Password is Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Required"),
  referralCode: yup.string(),
});

const loginuserValidation = yup.object().shape({
  email: yup.string().email("Please enter a valid email").required("Required"),
  password: yup.string().required("Required"),
});

const contactValidation = yup.object().shape({
  email: yup.string().email("Please enter a valid email").required("Required"),
  name: yup.string().required("Required"),
  message: yup.string().required("Required"),
});

const otpValidation = yup.object().shape({
  otp: yup.string().required("Required"),
});

const emailValidation = yup.object().shape({
  email: yup.string().email("Please enter a valid email").required("Required"),
});

const enrollmentValidation = yup.object().shape({
  email: yup.string().email("Please enter a valid email").required("Required"),
  fullName: yup.string().required("Full name is required"),
  phone: yup.number().required("Phone number is required"),
  student: yup.string().required("Student name is required"),
  date: yup.string().required("Date is required"),
  couponData: yup.string(),
  course: yup.object().required("Course is required"),
  coupon: yup.boolean(),
  referralPoint: yup.boolean(),
});
const passwordlValidation = yup.object().shape({
  password: yup.string().min(5).required("Password is Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});

export {
  registerUserValidation,
  loginuserValidation,
  otpValidation,
  emailValidation,
  passwordlValidation,
  contactValidation,
  enrollmentValidation,
};
