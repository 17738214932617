import React, { useState } from "react";
import { otpValidation } from "../service/validation";
import { verifyOtp } from "../service/api";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import login_bg from "../assets/background-img/login-bg.webp";
// import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";

export default function Otp() {
  const location = window.location.search;
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const param = new URLSearchParams(location);
  const email = param.get("email");
  const navigate = useNavigate();
  const onSubmit = async () => {
    try {
      const payload = {
        otp: otp,
        email: email,
      };
      setLoading(true);
      await verifyOtp(payload).then((res) => {
        toast.success(res.message);
        setLoading(false);
        navigate("/login");
      });
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  // const initialValues = {
  //   otp: "",
  // };

  // const {
  //   values,
  //   errors,
  //   isValid,
  //   isSubmitting,
  //   handleBlur,
  //   handleChange,
  //   handleSubmit,
  // } = useFormik({
  //   validateOnMount: true,
  //   initialValues: initialValues,
  //   validationSchema: otpValidation,
  //   onSubmit,
  // });

  return (
    <>
      <div className="sign__main mt-[100px] w-screen h-full">
        <div className="main h-full grid  lg:grid-cols-2 gap-8 md:gap-6 place-items-center">
          <div className="sign__left h-full hidden md:block">
            <img src={login_bg} alt="" className="h-fit object-contain" />
          </div>
          <div className="sign__right w-[90%] mx-auto">
            <form className="loginform">
              <div className="sign__title">
                <h2 className="w-full pb-8">Verify Otp</h2>
              </div>
              <div className="grid grid-cols-1 gap-8 w-full">
                {/* <span className="p-float-label">
                  <InputText
                    id="otp"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    className="w-full text-center"
                  />
                  <label htmlFor="email">OTP</label>
                </span> */}
                {/* <OtpInput
                  value={values.otp}
                  onChange={handleChange}
                  numInputs={5}
                  inputStyle={''}
                  renderInput={(props) => <input {...props} />}
                /> */}
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  inputStyle={"otp__input"}
                  inputType="tel"
                  renderInput={(props) => <input {...props} />}
                />
                {!otp && (
                  <small className="text-red-500"> Otp is required </small>
                )}
              </div>
            </form>
                <button
                  type="submit"
                  className="pri_btn w-full
                  mt-6"
                  disabled={!otp || otp.length !== 4 || loading}
                  onClick={onSubmit}
                >
                  {loading ? <i className="pi pi-spin pi-spinner"> </i> : ""}
                  Submit
                </button>
          </div>
        </div>
      </div>
    </>
  );
}
