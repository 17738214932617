import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import register_bg from "../assets/background-img/login-bg.webp";
import { useFormik } from "formik";
import { registerUserValidation } from "../service/validation";
import { registerUser } from "../service/api";

function Register() {
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    const payload = {
      ...values,
    };

    await registerUser(payload).then((res) => {
      toast.success("otp sent successful");
      navigate(`/otp?email=${values.email}`);
    });
  };

  const initialValues = {
    fullName: "",
    email: "",
    phoneNumber: "",
    referralCode: "",
    password: "",
    confirmPassword: "",
  };

  const {
    errors,
    isValid,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    validateOnMount: true,
    initialValues: initialValues,
    validationSchema: registerUserValidation,
    onSubmit,
  });

  return (
    <div className="sign__main mt-[100px] w-screen h-full">
      <div className="main h-full grid  lg:grid-cols-2 gap-8 md:gap-6 place-items-center">
        <div className="sign__left h-full hidden lg:block">
          <img src={register_bg} alt="" className="h-fit object-contain" />
        </div>
        <div className="sign__right h-full">
          <form className="loginform" onSubmit={handleSubmit}>
            <div className="sign__title">
              <h2 className="pb-8">Fill in your details to join now</h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-4 ">
              <span className="p-float-label">
                <InputText
                  id="fullName"
                  name="fullName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  className="w-full"
                />
                <label htmlFor="email">Full Name</label>
              </span>
              {errors.fullName && (
                <small className="text-red-500">{errors.fullName}</small>
              )}
              <span className="p-float-label">
                <InputText
                  id="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  className="w-full"
                />
                <label htmlFor="email">Email</label>
              </span>
              {errors.email && (
                <small className="text-red-500">{errors.email}</small>
              )}
              <span className="p-float-label">
                <InputText
                  id="phoneNumber"
                  name="phoneNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  className="w-full"
                />
                <label htmlFor="phoneNumber">Phone Number</label>
              </span>
              {errors.phoneNumber && (
                <small className="text-red-500">{errors.phoneNumber}</small>
              )}
              <span className="p-float-label">
                <InputText
                  id="referralCode"
                  name="referralCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="w-full"
                />
                <label htmlFor="referral">Referral Code (optional)</label>
              </span>
              <span className="p-float-label">
                <Password
                  id="password"
                  toggleMask
                  className="w-full"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="password">Password</label>
              </span>
              {errors.password && (
                <small className=" text-red-500">{errors.password}</small>
              )}
              <span className="p-float-label">
                <Password
                  id="confirmPassword"
                  toggleMask
                  feedback={false}
                  className="w-full"
                  name="confirmPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="password">Confirm Password</label>
              </span>
              {errors.confirmPassword && (
                <small className=" text-red-500">
                  {errors.confirmPassword}
                </small>
              )}

              <button className="pri_btn" disabled={isSubmitting || !isValid}>
                {isSubmitting ? <i className="pi pi-spin pi-spinner"> </i> : ""}
                Submit
              </button>
            </div>
            <div className="grid gap-0 text-center">
              <small className=" mt-3">
                Already have an account?
                <Link to="/login" className=" text-[var(--primary)]">
                  Login
                </Link>
              </small>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Register;
