import React from 'react';


export default function HeroTwo({head, image, bgposition, bgsize}) {
    return (
        <div className="heroTwo__main" style={{backgroundImage: `url(${image}) `, backgroundPosition: `${bgposition}`, backgroundSize: `${bgsize}`}}>
            <div className="heroTwo__container">
                <h3 className='head__three'> {head}</h3>
            </div>
        </div>
    )
}
