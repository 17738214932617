import React from 'react';
import './chat.css';
export default function Chat() {
    return (
        <div className="chat__us">
            <a href='https://wa.me/message/JA4RVXU5ELZVH1'  target='_blank'  rel="noreferrer"> 
             
                {/* <img src={whatsappIcon} alt="" className='h-[70px]' /> */}
            </a>

            <div className="chat__popup">

            </div>
        </div>
    )
}
