import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import learning_img1 from "../assets/background-img/mylearning-img1.png";
import myLearningData from "../data/myLearning.json";
import { useRecoilValue } from "recoil";
import { user } from "../atom/userAtom";
import { getUserEnrolledCourses } from "../service/api";

export default function MyLearning() {
  const [enrolled, setEnrolled] = useState([]);
  const userData = useRecoilValue(user);
  const navigate = useNavigate();
  const setMeetingLink = (data) => {
    const { meetingId, password } = data;
    navigate(`/zoom?meetingId=${meetingId}&password=${password}`);
  };
  useEffect(() => {
    const getEnrolled = async () => {
      const result = await getUserEnrolledCourses();
      setEnrolled(result.data.data);
    };
    getEnrolled();
  }, []);
  return (
    <div className="mt-[120px] w-screen h-full">
      <div className=" h-full lg:h-[40vh] flex items-center justify-center bg-learning ">
        <h2 className="text-center">Hi, {userData?.fullName}</h2>
      </div>
      <div className="main h-full grid gap-8 md:gap-6 place-items-center py-10">
        <h4 className="text-xl bold w-full text-center">
          Courses you enrolled for
        </h4>
        <div className="grid gap-5 lg:gap-10 mt-[10px]">
          {enrolled?.map((res, key) => (
            <div
              className="card grid md:grid-cols-2 gap-4 md:gap-6 place-items-center relative"
              key={key}
            >
              <img
                src={res?.image}
                alt=""
                className="h-[300px] w-full object-cover rounded-lg"
              />
              <div className="grid gap-4">
                <h3 className="text-2xl bold">{res?.courseTitle}</h3>
                <div className=" leading-5">
                  <p> Age: {res?.age}</p>
                  <p> Prequisite: {res?.prequisite}</p>
                  <p> Duration: {res?.duration}</p>
                </div>
                <div className="grid gap-4 lg:w-[93%] leading-6 ">
                  <p className="line-clamp-3">{res?.fullDescription}</p>
                </div>

                <div className=" absolute top-0 right-0 p-3 bg-gray-100 rounded-md bold">Cohort {res?.cohort}</div>
                <button
                  className="pri_btn w-fit"
                  onClick={() => setMeetingLink(res?.meetingDetails)}
                >
                  Join Zoom class
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
