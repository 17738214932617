import Aos from "aos";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { countryState } from "./atom/countryAtom";
import axios from "axios";
import { getCountry } from "./service/api";
function App() {
  const [country, setCountry] = useRecoilState(countryState);
  var request = new XMLHttpRequest();
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  useEffect(() => {
    request.open(
      "GET",
      "https://api.ipdata.co/?api-key=67dc168e3565dd5f0ffe8bc9d94415f00db03d4318011ccd09026933"
    );

    request.setRequestHeader("Accept", "application/json");

    request.onreadystatechange = function () {
      if (this.readyState === 4) {
        const data = JSON.parse(this.responseText).country_name;
        setCountry(data);
      }
    };

    request.send();

    
    const showCountry = async () => {
      const data = await getCountry();
      console.log(data);
    };

    showCountry()
  }, []);

  return <div className="App"></div>;
}

export default App;
