import React from "react";
import HeroTwo from "../components/heroTwo/HeroTwo";
import { Link, useParams } from "react-router-dom";
import blog from "../assets/background-img/blog1.png";
import smileIcon from "../assets/icons/blog-smile-img.png";
import blogData from "../data/blog.json";

function BlogDetails() {
  const params = useParams();
  const filteredData = blogData.filter((item) => item.id === Number(params.id));
  console.log(filteredData);
  return (
    <div className="blog__container">
      <HeroTwo head="Blog 1" bgposition="bottom" bgsize="cover" />
      <div className="main__blog mt-[100px]  w-screen h-full">
        <div className="main h-full ">
          <Link className="paragraph flex items-center gap-3" to="/blog">
            <i className="pi pi-arrow-left"></i>
            Back to Blog page
          </Link>

          <div className="body__blog grid place-items-center gap-4 lg:py-[20px]">
            <h2 className="w-full lg:w-[60%] text-center">
              {filteredData[0]?.title}
            </h2>
            <div className=" h-full ">
              <img src={blog} alt="" className="h-fit object-cover" />
            </div>

            <p className="paragraph lg:w-[60%] ">{filteredData[0]?.text}</p>
            <div className="text__blog lg:w-[56.5%] leading-loose">
              {filteredData[0]?.reasons?.map((res) => (
                <ol>
                  <li>{res}</li>
                </ol>
              ))}
            </div>
          </div>
        </div>

        <div className="main h-full lg:h-[50vh]  ">
          <div className="card bg-[var(--black)] h-[30vh] grid relative ">
            <div className="w-[80%] grid md:grid-cols-[9fr,3fr] place-items-center mx-auto">
              <h2 className="text-[var(--green)] lg:w-[90%] text-[2.7rem] leading-[1.1]">
                Up-skill your kids on Charis Coding Club
              </h2>
              <button className="white_btn w-fit">Enrol Now</button>
              <div className="h-full absolute top-[26%] right-[34%] ">
                <img
                  src={smileIcon}
                  alt=""
                  className="h-[50px] object-contain"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogDetails;
