import React from "react";
import entrustBg from "../../assets/background-img/entrust-bg.webp";
import entrustChildData from "../../data/entrustChild.json";

export default function EntrustChild() {
  return (
    <div className="mt-[100px] w-screen h-full lg:h-[80vh]">
      <div className="main  grid  md:grid-cols-2 items-center gap-10 h-full place-content-center">
        <div className="w-full lg:h-[80vh]">
          <img src={entrustBg} className=" w-full h-full object-cover rounded-3xl" />
        </div>
        <div className="grid gap-4">
          <h2 className="w-[60%]">Why Entrust Your Child With Us?</h2>
          <p>
            We aim to teach your child not just coding, but the skills to ask
            tough questions and solve tomorrow's problems
          </p>
          <div className="grid gap-3">
            {entrustChildData.map((res, key) => (
              <div className={ res["bg-color"]} key={key}>
                <h4 className="semiBold text-md">{res.title}</h4>
                <p>{res.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
