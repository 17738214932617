import axios from "../hooks/axios";

const baseUrl = "https://api.chariscodingclub.com";
// const baseUrl = "http://localhost:8080";

const loginUser = async (payload) =>
  await axios
    .post(`${baseUrl}/login`, payload)
    .then((response) => response.data);

const registerUser = async (payload) =>
  await axios
    .post(`${baseUrl}/register`, payload)
    .then((response) => response.data);

const forgotPassword = async (payload) =>
  await axios
    .post(`${baseUrl}/forgot-password`, payload)
    .then((response) => response.data);

const updateProfile = async (payload) =>
  await axios
    .post(`${baseUrl}/update-profile`, payload)
    .then((response) => response.data);

const verifyOtp = async (payload) =>
  await axios
    .post(`${baseUrl}/verify-otp`, payload)
    .then((response) => response.data);

const resetPassword = async (payload) =>
  await axios
    .post(`${baseUrl}/reset-password`, payload)
    .then((response) => response.data);

const applyPromo = async (payload) =>
  await axios
    .post(`${baseUrl}/apply-promo`, payload)
    .then((response) => response.data);

const subscribeToNewsletter = async (payload) =>
  await axios
    .post(`${baseUrl}/newsletter-subscribe`, payload)
    .then((response) => response.data);

const contact = async (payload) =>
  await axios
    .post(`${baseUrl}/contact`, payload)
    .then((response) => response.data);

const getUser = async (payload) =>
  await axios
    .post(`${baseUrl}/profile`, payload)
    .then((response) => response.data);

const getAllCourses = async () => await axios.get(`${baseUrl}/list-courses`);

const getProfile = async () => await axios.get(`${baseUrl}/profile`);

const getCourseById = async (id) =>
  await axios.get(`${baseUrl}/course?id=${id}`);

const pay = async (payload) => await axios.post(`${baseUrl}/pay`, payload);

const getUserEnrolledCourses = async () =>
  await axios.get(`${baseUrl}/user-enrolled-courses`);

const paymentCallback = async (payload) =>
  await axios
    .post(`${baseUrl}/verify-payment`, payload)
    .then((response) => response.data);

const applyReferral = async (payload) =>
  await axios
    .post(`${baseUrl}/apply-referral`, payload)
    .then((response) => response.data);

const getMsdkSignature = async (payload) =>
  await axios
    .post(`${baseUrl}/msig`, payload)
    .then((response) => response.data);

const verifyPayment = async (payload) =>
  await axios
    .post(`${baseUrl}/transaction-verification`, payload)
    .then((response) => response.data);

const getCountry = async () =>
  await axios.get("https://developer.mozilla.org/api/v1/whoami");

export {
  loginUser,
  registerUser,
  forgotPassword,
  updateProfile,
  resetPassword,
  applyPromo,
  subscribeToNewsletter,
  contact,
  getUser,
  getAllCourses,
  getCourseById,
  pay,
  paymentCallback,
  verifyOtp,
  getUserEnrolledCourses,
  applyReferral,
  getMsdkSignature,
  getProfile,
  verifyPayment,
  getCountry
};
