import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { user } from "../../atom/userAtom";
import { authState } from "../../atom/authAtom";
import logo from "../../assets/logo/logo.png";
import points from "../../assets/icons/points.svg";
import { toast } from "react-toastify";
import { Avatar } from "primereact/avatar";
import { useState } from "react";

function Header() {
  const [auth, setAuth] = useRecoilState(authState);
  const [userData, setUserData] = useRecoilState(user);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const logout = () => {
    setAuth(null);
    setUserData(null);
    toast.success("Log out successful");
  };

  const openModal = () => {
    setOpen(!open);
  };
  const openMobileMenu = () => {
    setModal(!modal);
  };

  return (
    <div className="fixed top-0 z-50 left-0 bg-white flex items-center justify-center w-screen h-[90px]">
      <div className="main flex justify-between items-center ">
        <Link to="/" className="h-[80px]">
          <img src={logo} alt="" className="object-contain h-full" />
        </Link>
        <div className="hidden lg:flex gap-4">
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/courses">Courses</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/faq">Faq</Link>
          {auth && auth.length > 0 ? (
            <Link to="/my-learning"> My Learning</Link>
          ) : (
            ""
          )}
          <Link to="/contact">Contact us</Link>
        </div>
        <>
          {auth && auth.length > 0 ? (
            <>
              <div className="hidden lg:flex gap-4 ">
                <div className="flex items-center gap-2">
                  <img
                    src={points}
                    alt=""
                    className="h-[20px] object-contain"
                  />
                  <p>{userData?.referralPoint} Points</p>
                </div>
                <Avatar
                  shape="circle"
                  icon="pi pi-user"
                  className=" bg-red-500 text-white rounded-full h-[40px] w-[40px] cursor-pointer"
                  onClick={openModal}
                />
              </div>
              {open ? (
                <>
                  <div
                    className="fixed bg-transparent w-full h-full left-0 top-0"
                    onClick={openModal}
                  ></div>
                  <div className=" absolute bg-white p-5 rounded-md shadow-lg top-[80px] right-[10%] flex flex-col gap-3">
                    <Link
                      to="/my-learning"
                      className="flex items-center gap-3 cursor-pointer"
                      onClick={openModal}
                    >
                      <i className="pi pi-book text-sm"></i>
                      My Learning
                    </Link>

                    <Link
                      to="/profile"
                      className="flex items-center gap-3 cursor-pointer"
                      onClick={openModal}
                    >
                      <i className="pi pi-user text-sm"></i>
                      Profile
                    </Link>
                    <div
                      className="flex items-center gap-3 cursor-pointer"
                      onClick={logout}
                    >
                      <i className="pi pi-power-off text-sm"></i>
                      Logout
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            <div className="hidden lg:flex gap-4 ">
              <button
                className="bg-[var(--primary)] rounded-full px-6 py-2"
                onClick={() => navigate("/register")}
              >
                Join Now
              </button>
              <button
                className="rounded-full border border-[var(--primary)] px-6 py-2"
                onClick={() => navigate("/login")}
              >
                Sign In
              </button>
            </div>
          )}
        </>
        <div className="block lg:hidden" onClick={openMobileMenu}>
          <i className="pi pi-bars"></i>
        </div>
      </div>
      {modal && (
        <div className="block lg:hidden">
          <div className="fixed bg-gray-100 left-0 bottom-0 w-screen h-screen z-50 ">
            <div className="flex items-center justify-between px-5 bg-white h-[90px]" >
              <Link to="/" className="h-[80px]">
                <img src={logo} alt="" className="object-contain h-full" />
              </Link>
              <div className="" onClick={openMobileMenu}>
                <i className="pi pi-times"></i>
              </div>
            </div>
            <div className="grid gap-6 p-10 ">
              <Link
                to="/"
                className="flex items-center gap-3"
                onClick={openMobileMenu}
              >
                <i className=" pi pi-building"></i> Home
              </Link>
              <Link
                to="/about"
                className="flex items-center gap-3"
                onClick={openMobileMenu}
              >
                <i className=" pi pi-box"></i> About
              </Link>
              <Link
                to="/courses"
                className="flex items-center gap-3"
                onClick={openMobileMenu}
              >
                <i className=" pi pi-list"></i> Courses
              </Link>
              <Link
                to="/blog"
                className="flex items-center gap-3"
                onClick={openMobileMenu}
              >
                <i className=" pi pi-megaphone"></i> Blog
              </Link>
              <Link
                to="/faq"
                className="flex items-center gap-3"
                onClick={openMobileMenu}
              >
                <i className=" pi pi-info-circle"></i> Faq
              </Link>
              {auth && auth.length > 0 ? (
                <Link
                  to="/my-learning"
                  className="flex items-center gap-3"
                  onClick={openMobileMenu}
                >
                  <i className=" pi pi-folder-open"></i> My Learning
                </Link>
              ) : (
                ""
              )}
              <Link
                to="/contact"
                className="flex items-center gap-3"
                onClick={openMobileMenu}
              >
                <i className=" pi pi-globe"></i> Contact us
              </Link>
              <>
                {auth && auth.length > 0 ? (
                  <>
                    <div className="hidden lg:flex gap-4 ">
                      <div className="flex items-center gap-2">
                        <img
                          src={points}
                          alt=""
                          className="h-[20px] object-contain"
                        />
                        <p>{userData?.referralPoint} Points</p>
                      </div>
                      <Avatar
                        shape="circle"
                        icon="pi pi-user"
                        className=" bg-red-500 text-white rounded-full h-[40px] w-[40px] cursor-pointer"
                      />
                    </div>

                    <>
                      <Link
                        to="/profile"
                        className="flex items-center gap-3 cursor-pointer"
                      >
                        <i className="pi pi-user text-sm"></i>
                        Profile
                      </Link>

                      <div
                        className="flex items-center gap-3 cursor-pointer"
                        onClick={logout}
                      >
                        <i className="pi pi-power-off text-sm"></i>
                        Logout
                      </div>
                    </>
                  </>
                ) : (
                  <div className=" grid gap-4 ">
                    <button
                      className="bg-[var(--primary)] rounded-full px-6 py-2"
                      onClick={() => navigate("/register")}
                    >
                      Join Now
                    </button>
                    <button
                      className="rounded-full border border-[var(--primary)] px-6 py-2"
                      onClick={() => navigate("/login")}
                    >
                      Sign In
                    </button>
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
