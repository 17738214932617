import React from 'react'
import HeroTwo from '../components/heroTwo/HeroTwo';

export default function Payment() {
    return (
        <div>
                <HeroTwo 
         head='Payment Page'
         bgposition='top'
         bgsize='contain'
         />
        </div>
    )
}
