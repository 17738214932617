import React, { useState } from "react";
import { Link, useHistory, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { user } from "../atom/userAtom";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import { emailValidation } from "../service/validation";
import { useFormik } from "formik";
import { forgotPassword } from "../service/api";
import login_bg from "../assets/background-img/login-bg.webp";

function ForgotPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (values) => {
    try {
      setLoading(true);
      await forgotPassword(values).then((res) => {
        resetForm()
        setLoading(false);
        toast.success("A reset link has been sent to your mail");
      });
    } catch (error) {}
  };

  const initialValues = {
    email: "",
  };

  const {
    errors,
    isValid,
    values,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm
  } = useFormik({
    validateOnMount: true,
    initialValues: initialValues,
    validationSchema: emailValidation,
    onSubmit,
  });

  return (
    <>
      <div className="sign__main mt-[100px] w-screen h-full">
        <div className="main h-full grid  lg:grid-cols-2 gap-8 md:gap-6 place-items-center">
          <div className="sign__left h-full hidden md:block">
            <img src={login_bg} alt="" className="h-fit object-contain" />
          </div>
          <div className="sign__right w-[90%] mx-auto">
            <form className="loginform" onSubmit={handleSubmit}>
              <div className="sign__title pb-8">
                <h2 className="w-full ">Forgot password?</h2>
                <p>Enter email address to to recover password.</p>
              </div>
              <div className="grid grid-cols-1 gap-3 w-full">
                <div>
                  <span className="p-float-label">
                    <InputText
                      id="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      className="w-full"
                    />
                    <label htmlFor="email">Email</label>
                  </span>
                  {errors.email && (
                    <small className="text-red-500">{errors.email}</small>
                  )}
                </div>
                <button className="pri_btn" disabled={!isValid || loading}>
                  {loading ? <i className="pi pi-spin pi-spinner"> </i> : ""}
                  Reset
                </button>
              </div>
              <div className="grid gap-0 text-center">
                <p className=" text-sm mt-3">
                  Don't have an account{" "}
                  <Link to="/register" className=" text-[var(--primary)]">
                    {" "}
                    Join Now
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
