import axios from "axios";
import { toast } from "react-toastify";

const getToken = () => {
  return localStorage.getItem("recoil-persist"); // Assuming your JWT token is stored in localStorage
};

axios.interceptors.request.use(function (config) {
  const token = JSON.parse(getToken())?.auth;
  // If token exists, add it to request headers
  config.headers.API_KEY = "gsk_live_HKw3QrIbjvBszMkSdFeTWPfo0Vx8Zm";
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response) {
      toast.error(error?.response?.data.message);
    }
  }
);

export default axios;
