import React from "react";
import { Link } from "react-router-dom";
import footerPattern from "../../assets/patterns/footer-pattern.svg";
import footerPattern3 from "../../assets/patterns/footer-pattern3.svg";
import footerPattern2 from "../../assets/icons/whatsapp.svg";
import logo from "../../assets/logo/logo.png";
import { emailValidation } from "../../service/validation";
import { useFormik } from "formik";
import { subscribeToNewsletter } from "../../service/api";
import { toast } from "react-toastify";

function Footer() {
  const year = new Date().getFullYear();

  const openwhatsApp = () => {
    window.open("https://wa.me/message/JA4RVXU5ELZVH1", "_blank")
  }
  const onSubmit = async (values) => {
    try {
      await subscribeToNewsletter(values).then(({ data }) => {
        resetForm();
        toast.success("Subscription successful");
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const initialValues = {
    email: "",
  };

  const {
    errors,
    values,
    isValid,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    validateOnMount: true,
    initialValues: initialValues,
    validationSchema: emailValidation,
    onSubmit,
  });

  return (
    <div className=" bg-[var(--yellow)] w-screen h-full lg:h-[80vh] relative">
      <div className="main grid gap-2 text-center items-center ">
        <h2 className="text-2xl lg:text-4xl bold mt-8">
          Subscribe to our Newsletter
        </h2>
        <p className="mx-auto">
          Join now to get updates on latest courses and services
        </p>
        <form
          onSubmit={handleSubmit}
          className="grid w-full lg:w-[40%] mx-auto my-[20px] gap-4"
        >
          <input
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            className="rounded-full border border-[var(--primary)] px-6 py-2"
            placeholder="Enter your email"
          />
          <button className="pri_btn" disabled={isSubmitting || !isValid}>
            {isSubmitting ? <i className="pi pi-spin pi-spinner"> </i> : ""}
            Subscription
          </button>

          <div className="h-[40px] absolute top-[60%] left-10 lg:left-[20%]">
            <img src={footerPattern} alt="" className="h-full object-contain" />
          </div>
          <div className="h-[30px] absolute right-[20%] lg:top-[44%] top-[50%]">
            <img
              src={footerPattern3}
              alt=""
              className="h-full object-contain"
            />
          </div>
        
        </form>
      </div>
      <div className="mt-[120px] main flex justify-between items-center ">
        <Link to="/" className="h-[80px]">
          <img src={logo} alt="" className="object-contain h-full" />
        </Link>
        <div className="hidden lg:flex gap-4">
          <Link to="/">Home</Link>
          <Link to="about">About</Link>
          <Link to="course">Courses</Link>
          <Link to="blog">Blog</Link>
          <Link to="faq">Faq</Link>
          <Link to="contact">Contact us</Link>
          <Link to="privacy-policy">Privacy Policy</Link>
        </div>
        <div className=" fixed right-10 bottom-10 flex items-center flex-col cursor-pointer group" onClick={openwhatsApp}>
          <img src={footerPattern2} alt="" className="h-[70px] object-cover group-hover:animate-bounce" />
          <h4 className="semiBold absolute group-hover:animate-pulse bottom-[-20px] w-[100px]">Reach us here </h4>
        </div>
      </div>
      <div className="main mt-[20px] text-center py-4">
        <p>© {year} Charis coding club. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
