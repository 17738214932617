import React from "react";
import HeroTwo from "../components/heroTwo/HeroTwo";
import about_bg from "../assets/background-img/about-bg.png";
import founder from "../assets/background-img/founder.webp";
import aboutData from "../data/about.json";
import aboutPattern from "../assets/patterns/about-pattern.svg";
import aboutPattern2 from "../assets/patterns/about-pattern2.svg";

function About() {
  return (
    <div className="about__container">
      <HeroTwo head="About Us" />
      <div className="mt-[100px] lg:mt-[10px] w-screen h-full relative">
        <div className="main h-full grid  md:grid-cols-2 lg:py-20 gap-8 md:gap-6 place-items-center ">
          <div className=" grid gap-5 order-2 md:order-1">
            <h1 className="">We are a team of creators & innovators</h1>
            <div className="grid gap-5">
              <p>
                Charis coding club was founded in 2020. The mission of the
                company is to make programming fun, engaging and attractive for
                children worldwide.
              </p>
              <p>
                The Vision of the club is to raise the next generation of
                tech-savvy individuals who are ready to compete globally in
                today’s fast moving digital age.
              </p>
            </div>
          </div>
          <div className="w-full lg:w-[70%] order-1 md:order-2">
            <img src={about_bg} alt="" className="w-fit object-contain" />
          </div>
          <div className=" absolute top-[24%] left-0">
            <img src={aboutPattern} alt="" />
          </div>
          <div className="hidden md:block absolute top-[29%] lg:right-[25%]">
            <img src={aboutPattern2} alt="" />
          </div>
        </div>

        <div className="  text-center h-full py-10 lg:py-2 place-items-center ">
          <h2 className=" ">Meet our Founder</h2>
          <div className=" grid gap-4 md:grid-cols-2 lg:h-[90vh] place-items-center main">
            <div className="w-full ">
              <img src={founder} alt="" className="w-fit object-contain" />
            </div>
            <div className="grid gap-6 text-start lg:w-[80%]">
              <p>
                A first class graduate of Computer Science from the premier
                University in Nigeria, University of Ibadan, Uayemen Nwankwo
                also holds an MBA from Heriot-Watt University, Edinburgh,
                Scotland and several other qualifications and certifications
                including a Postgraduate Diploma in Education from Nottingham
                University, United Kingdom. Uayemen has over 13 years of IT
                experience.
              </p>
              <p>
                She has worked with some of the major telecommunications
                companies in Nigeria (Globacom, Etisalat and Huawei Technologies
                Limited).
              </p>
              <p>
                She is passionate about transferring technological skills to the
                next generation and believes every child has so much hidden
                potentials that must be unlocked.
              </p>
            </div>
          </div>
        </div>

        <div className="main grid gap-2 text-center h-full py-10  place-items-center">
          <div className="grid gap-4 ">
            <h2 className="">The values we live By</h2>
            <p className="lg:w-[87%] mx-auto">
              These guiding principles fosters great relationships between our
              tutors, parents and students.
            </p>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {aboutData.map((res) => (
              <div className="card bg-[var(--white)] h-[20vh]">
                <img
                  src={res.image}
                  alt=""
                  className="h-[20px] w-[20px] object-cover mb-3"
                />
                <h4 className="semiBold text-md">{res.title}</h4>
                <p className="w-[85%]">{res.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
