import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { user } from "../atom/userAtom";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { authState } from "../atom/authAtom";
import login_bg from "../assets/background-img/login-bg.webp";
import { loginuserValidation } from "../service/validation";
import { useFormik } from "formik";
import { loginUser } from "../service/api";

function Login() {
  const [userData, setUserData] = useRecoilState(user);
  const [auth, setAuth] = useRecoilState(authState);

  const navigate = useNavigate();
  const onSubmit = async (values) => {
    try {
      await loginUser(values).then(({data}) => {
          if (data.data.status === 'pending') {
            navigate(`/otp?email=${values.email}`);
          } else {
            setAuth(data.token);
            setUserData(data.data)
            navigate('/')
            toast.success("Login successful");
          }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const register = () => {
    navigate("/register");
  };

  const forgotPassword = () => {
    navigate("/forgot-password");
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const {
    errors,
    isValid,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    validateOnMount: true,
    initialValues: initialValues,
    validationSchema: loginuserValidation,
    onSubmit,
  });

  return (
    <div className="sign__main mt-[100px] w-screen h-full">
      <div className="main h-full grid  lg:grid-cols-2 gap-8 md:gap-6 place-items-center">
        <div className="sign__left h-full hidden md:block">
          <img src={login_bg} alt="" className="h-fit object-contain" />
        </div>
        <div className="sign__right">
          <form className="loginform" onSubmit={handleSubmit}>
            <div className="sign__title">
              <h2 className="w-[90%] pb-8">Welcome back, login to continue</h2>
            </div>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <span className="p-float-label">
                  <InputText
                    id="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    className="w-full"
                  />
                  <label htmlFor="email">Email</label>
                </span>
                {errors.email && (
                  <small className="text-red-500">{errors.email}</small>
                )}
              </div>
              <div>
                <span className="p-float-label">
                  <Password
                    id="password"
                    name="password"
                    feedback={false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    toggleMask
                    className="w-full"
                  />
                  <label htmlFor="email">Password</label>
                </span>
                {errors.password && (
                  <small className="text-red-500">{errors.password}</small>
                )}
              </div>
              <p className="subtitle pt-3 text-sm">
                Forgotten password?
                <Link to="/forgot-password" className="text-[var(--primary)]">
                  {" "}
                  Reset{" "}
                </Link>
              </p>
              <button className="pri_btn" disabled={isSubmitting || !isValid}>
                {isSubmitting ? <i className="pi pi-spin pi-spinner"> </i> : ""}
                Login
              </button>
            </div>
            <div className="grid gap-0 text-center">
              <p className=" mt-3 text-sm">
                Don't have an account{" "}
                <Link to="/register" className=" text-[var(--primary)]">
                  {" "}
                  Join Now
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
