import React from "react";

export default function Category({children}) {

  return (
    <div className="w-screen h-full bg-[var(--secondary)]">
      <div className="main grid gap-3 place-content-center py-10">
        <h2 className="text-2xl lg:text-4xl bold ">
          Explore Our Classes by Category
        </h2>
        <p className="mx-auto">
          Upskill your child based on the categories below
        </p>
      
      </div>
      {children}
    </div>
  );
}
