import React from 'react';
import policy_bg from '../assets/background-img/policy-bg.webp';

export default function Policy() {
  return (
    <div className="mt-[100px] w-screen h-full relative">
        <div className="main h-full grid  md:grid-cols-2 lg:h-[60vh] gap-8 md:gap-6 place-items-center ">
            <div className=" w-full grid gap-2 order-2 md:order-1">
                <h1>
                Privacy Policy
                </h1>
                <p>
                Last updated: January 8, 2024
                </p>
            </div>
            <div className="w-full h-[60vh] order-1 md:order-2 relative">
                <img src={policy_bg} alt="" className="h-full object-contain" />
            </div>
          
        </div>
        <div className="main h-full grid gap-8 md:gap-6 place-items-center py-20 leading-6  ">
            <div className="grid gap-5">
                <p>
                At Charis Coding Club your privacy is important to us. Our Privacy Policy describes the information we collect, how we collect the information, the reasons we collect information, and how we share or use the information we collect. This Privacy Policy also describes the choices you have with the information we collect, including how you can manage, update, or request to delete information.

                </p>
                <p>
                    Please take a moment to review this Privacy Policy. It is important that you understand this Privacy Policy. By using our Platform, you are agreeing to the terms of this Privacy Policy. If you have any questions or concerns about this Privacy Policy, you may <span className='text-[var(--primary)]'>Contact Us </span> at any time.
                </p>
            </div>
            <div className="grid gap-5">
                <h4 className='text-xl bold pt-4'>
                Your Personal Information
                </h4>
                <p>
                We collect information through technology for website visitors who do not engage with us directly, please see the Technology and cookies and trackers section below for more information. If you interact on our website, we will track your activities online, but we do not know who you are.  
                </p>
                <p>
                However, if you submit a form with your contact information, we will engage with you as requested and may follow up with you to further build a relationship, generally through Communications and  Marketing. You can opt out of marketing communications at any time. 
                </p>
                <p>
                    <span className='text-[var(--red)]'>How do we collect this information? </span>
                    How do we collect this information? This information is typically provided by you voluntarily. Where it is not voluntary, it may be in the realm of employment or legal proceedings and follow the requirements of applicable law. In addition, we buy services or information that help us learn more about you and communicate with you.
                </p>
                <p>
                    <span className='text-[var(--red)]'>
                    Why do we collect this information?  </span>
                     We respond or initiate communications in provision of the Activities or in response to a request. This is based on the requirement to provide you services or our legitimate interest to grow our business and understand our Customers and their needs better. Our legitimate interest in this relates to sending you the request, in which you may choose to respond or not and can choose to ask us not to contact you for those purposes. Given this, our legitimate interest is not outweighed by the risk to you.
                </p>
            </div>
        </div>
    </div>
  )
}
