import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import { countryState } from "../../atom/countryAtom";
import { formatCurrency } from "../../service/util";
import { Link } from "react-router-dom";

function CourseCard({
  courseImg,
  age,
  localPrice,
  internationalPrice,
  title,
  duration,
  description,
  prequisite,
  tag,
  id,
  learnMore
}) {
  const navigate = useNavigate();

  const country = useRecoilValue(countryState);

  const enroll = (id) => {
    navigate(`/enrollment?id=${id}`);
  };
  const learn = () => {
    navigate("/contact");
  };

  return (
    <>
      <div className="bg-white relative h-[480px] shadow-lg p-5 rounded-xl">
        <div className="">
          <div className="">
            <div className="tag  z-20 absolute bg-orange-100 rounded-full text-sm font-bold p-1 px-3 text-orange-600 top-2 right-2">
              {tag}
            </div>

            <div className="h-[200px]">
              <img
                src={courseImg}
                alt=""
                className=" h-full w-full rounded-md object-cover"
              />
            </div>
            <h3 className="bold text-xl pt-3 ">{title}</h3>
            <h3 className="bold my-1 text-sm">
              {" "}
              {country !== "Nigeria"
                ? formatCurrency(internationalPrice, "USD")
                : formatCurrency(localPrice, "NGN")}
            </h3>
            <div className="text-sm grid gap-1">
              <p className="flex gap-2 ">
                Duration: <span> {duration} </span>
              </p>
              <p className="flex gap-2">
                Location: : <span> Online (Zoom)</span>
              </p>
              <p className="flex gap-2">
                Age: : <span> {age}</span>
              </p>

              <p className="flex gap-2">
                Prequisite: <span> {prequisite}</span>
              </p>

              {learnMore ? (
                <Link
                  to={`/course-detail/${id}`}
                  className="text-red-500 semiBold"
                >
                  Learn More
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
          <button className="pri_btn w-full mt-4" onClick={() => enroll(id)}>
            Enroll
          </button>
        </div>
      </div>
    </>
  );
}

export default CourseCard;
