import React from "react";
import roadmap from "../../assets/background-img/roadmap.webp";
import path from "../../assets/patterns/path.svg";
import { Link } from "react-router-dom";

export default function Roadmap() {
  return (
    <div className="w-screen h-full py-10 overflow-hidden ">
      <div className="main grid gap-2 text-center items-center ">
        <h2 className="text-2xl lg:text-4xl bold ">
          Supporting Children Development skills
        </h2>
        <p className="lg:w-[50%] mx-auto">
          Education is the process of facilitating learning, or the acquisition
          of knowledge, skills, values, morals, beliefs, and habits.
        </p>
        <div className="py-5 lg:w-[80%] mx-auto">
          <img src={roadmap} alt="" className="w-full object-contain" />
        </div>
        <div className="relative">
          <div className="h-[40px] absolute left-[5%] lg:left-[34%]">
            <img src={path} alt="" className="h-full object-contain" />
          </div>
          <Link to='/about' className="bg-[var(--primary)] w-fit rounded-full px-6 py-2">
            More About us
          </Link>
        </div>
      </div>
    </div>
  );
}
