import {
  Link,
  useHistory,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { passwordlValidation } from "../service/validation";
import { resetPassword } from "../service/api";
import { useFormik } from "formik";
import login_bg from "../assets/background-img/login-bg.webp";

function ResetPassword() {
  const [searchParam, setSearchParams] = useSearchParams()
  const email = searchParam.get('email');
  const token = searchParam.get('token');
  const navigate = useNavigate()
  const onSubmit = async (values) => {
    try {
      const payload = {
        email, 
        token,
        ...values
      }
      await resetPassword(payload).then((res) => {
        resetForm()
        toast.success("Password Reset successfully");
        navigate('/login')
      });
    } catch (error) {}
  };

  const initialValues = {
    password: "",
    confirmPassword: ""
  };

  const {
    errors,
    isValid,
    values,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm
  } = useFormik({
    validateOnMount: true,
    initialValues: initialValues,
    validationSchema: passwordlValidation,
    onSubmit,
  });


  return (
    <>
      <div className="sign__main mt-[100px] w-screen h-full">
        <div className="main h-full grid  lg:grid-cols-2 gap-8 md:gap-6 place-items-center">
          <div className="sign__left h-full hidden md:block">
            <img src={login_bg} alt="" className="h-fit object-contain" />
          </div>
          <div className="sign__right w-[90%] mx-auto">
            <form className="loginform" onSubmit={handleSubmit}>
              <div className="sign__title pb-8">
                <h2 className="w-full ">Reset password</h2>
                <p>Set a new password for your account so you can login</p>
              </div>
              <div className="grid grid-cols-1 gap-3 w-full">
                <div>
                  <span className="p-float-label">
                    <Password
                      id="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      toggleMask
                      feedback={false}
                      className="w-full"
                    />
                    <label htmlFor="email">New Password</label>
                  </span>
                  {errors.password && (
                    <small className="text-red-500">{errors.password}</small>
                  )}
                </div>
                <div>
                  <span className="p-float-label">
                    <Password
                      id="confirmPassword"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      toggleMask
                      feedback={false}
                      className="w-full"
                    />
                    <label htmlFor="email">Re-enter password</label>
                  </span>
                  {errors.confirmPassword && (
                    <small className="text-red-500">{errors.confirmPassword}</small>
                  )}
                </div>
                <button className="pri_btn" disabled={isSubmitting || !isValid}>
                  {isSubmitting ? (
                    <i className="pi pi-spin pi-spinner"> </i>
                  ) : (
                    ""
                  )}
                  Update password
                </button>
              </div>
              <div className="grid gap-0 text-center">
                <p className=" text-sm mt-3">
                  Don't have an account{" "}
                  <Link to="/register" className=" text-[var(--primary)]">
                    {" "}
                    Join Now
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
