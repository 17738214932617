import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { countryState } from "../atom/countryAtom";
import { toast } from "react-toastify";
import { user } from "../atom/userAtom";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import { authState } from "../atom/authAtom";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { enrollmentValidation } from "../service/validation";
import { useFormik } from "formik";
import moment from "moment";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from 'primereact/inputnumber';
        
import {
  applyPromo,
  applyReferral,
  getAllCourses,
  getProfile,
  pay,
  paymentCallback,
  verifyPayment,
} from "../service/api";
import { formatCurrency } from "../service/util";

const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

const flutterWaveButton = window.FlutterwaveCheckout;

export default function Enrollment() {
  const [currentUser, setCurrentUser] = useRecoilState(user);
  const [paymentModal, setPaymentModal] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const [url, setUrl] = useState("");
  const auth = useRecoilValue(authState);
  const navigate = useNavigate();
  const [course, setCourse] = useState({});
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gemsPayloading, setGemsPayloading] = useState(false);
  const [promoLoading, setPromoLoading] = useState(false);
  const country = useRecoilValue(countryState);
  const id = window.location.search?.split("=")[1];

  useEffect(() => {
    const getcourses = async () => {
      await getAllCourses().then(({ data }) => {
        setCourses(data.data);
        if (auth && auth?.length > 0) {
          getProfile().then(({ data }) => {
            setCurrentUser(data.data);
          });
        }
        if (id) {
          const filterData = data.data?.filter((item) => item._id === id);
          values.course = filterData[0];
          setCourse(filterData[0]);
        }
      });
    };

    getcourses();
  }, []);

  const applyReferralPoint = async () => {
    const payload = {
      courseId: course?._id,
    };
    await applyReferral(payload).then((res) => {
      if (res.data) {
        setCourse(res.data);
      }
      toast.success(res.message);
    });
  };
  // generate random string
  function generateRandomString(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // generate random string

  function generateRandomStrings(count, length) {
    let randomStrings = [];
    for (let i = 0; i < count; i++) {
      randomStrings.push(generateRandomString(length));
    }
    return randomStrings;
  }

  const applyDefualtPrice = async () => {
    let courseId = course?._id;
    if (courseId) {
      const filterData = courses.filter((item) => item._id === courseId);
      values.course = filterData[0];
      setCourse(filterData[0]);
    }
  };

  const savePaymentDetails = async (data, method, courseId, cohort, email) => {
    try {
      let payload = {
        data: data,
        name: values?.fullName,
        email: email,
        method: method,
        courseId: courseId,
        amount: country ==='Nigeria' ? course?.localPrice : course?.internationalPrice,
        cohort: cohort,
        promoCode: values?.couponData,
        referral: values?.referralPoint
      };
      await paymentCallback(payload).then((res) => {
        toast.success("payment successful");
        navigate("/");
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const createOrder = async (data, actions) => {
    let amount = course?.internationalPrice;

    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: amount,
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    savePaymentDetails(
      data,
      "paypal",
      values?.course?._id,
      values?.course?.cohort,
      values?.email
    );

    return actions.order.capture();
  };

  let makePayment = async () => {
    setLoading(true);
    let payload = {
      name: values.fullName,
      email: values.email,
      id: values?.course?._id,
      phoneNumber: values.phone,
      userReferral: values.referralPoint,
    };

    await pay(payload).then((res) => {
      let amount = course?.localPrice;

      flutterWaveButton({
        public_key: res?.data?.public_key,
        tx_ref: res?.data?.tx_ref,
        amount: res?.data?.customer?.amount,
        currency: "NGN",
        payment_options: "card",
        redirect_url: "",
        callback: async function (payment) {
          const payload = {
            transactionId: res?.data?.tx_ref,
          };
          await verifyPayment(payload);
        },
        onclose: function (incomplete) {
          setLoading(false);

          if (incomplete || window.verified === false) {
          } else {
            if (window.verified === true) {
              savePaymentDetails(
                res.data,
                "flutterwave",
                values?.course?._id,
                values?.course?.cohort,
                values?.email
              );
            } else {
            }
          }
        },
        customer: {
          email: res?.data?.customer?.email,
          phone_number: res?.data?.customer?.phoneNumber,
          name: res?.data?.customer?.name,
        },
        customizations: {
          title: res?.data?.customizations?.title,
          logo: res?.data?.customizations?.logo,
        },
      });
    });
  };
  let payWithGems = async () => {
    setGemsPayloading(true);

    // Example usage:
    let id = generateRandomStrings(1, 28)[0];

    const payload = {
      amount: course?.localPrice,
      callbackUrl: "chariscodingclub.com/courses",
      customer: {
        firstName: values.fullName,
        lastName: "",
        email: values.email,
        phoneNumber: "08101725687",
      },
      orderId: id,
      uniqueId: id,
      transactionReference: id,
      transactionDescription: `payment for ${values.course?.courseTitle}`,
    };

    const result = await axios.post(
      "https://connect.gemspaysolution.com/transactions/initialize",
      payload
    );
    setUrl(result.data.data.checkoutUrl);
    setPaymentModal(true);
    setGemsPayloading(false);
  };

  let closePaymentModal = () => {
    setPaymentModal(!paymentModal);
  };

  let usePromo = async () => {
    const payload = {
      code: values.couponData,
      id: course?._id,
      email: values.email,
    };
    setPromoLoading(true);
    await applyPromo(payload)
      .then((res) => {
        setPromoLoading(false);
        if (res.data) {
          setCourse(res.data);
        }
        toast.success(res?.message);
      })
      .catch((error) => {
        setPromoLoading(false);
      });
  };

  const onSubmit = async (values) => {
    try {
    } catch (error) {}
  };

  const openModal = () => {
    setTransfer(!transfer);
  };

  const initialValues = {
    email: currentUser?.email ?? "",
    fullName: currentUser?.fullName ?? "",
    phone: currentUser?.phoneNumber ?? "",
    student: "",
    date: moment().subtract(5, "year").toDate(),
    course: {},
    couponData: "",
    coupon: false,
    referralPoint: false,
  };

  const {
    errors,
    isValid,
    values,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    validateOnMount: true,
    initialValues: initialValues,
    validationSchema: enrollmentValidation,
    onSubmit,
  });

  useEffect(() => {
    if (values.referralPoint) {
      applyReferralPoint();
    } else {
      applyDefualtPrice();
    }
  }, [values.referralPoint]);

  useEffect(() => {
    if (values.referralPoint) {
      applyReferralPoint();
    } else {
      applyDefualtPrice();
    }
  }, [values.coupon]);

  useEffect(() => {
    setCourse(values.course);
    values.referralPoint = false;
  }, [values.course]);

  return (
    <div>
      <div className="enrollment__main mt-[120px] w-screen h-full pb-10">
        <div className="main h-full grid  lg:grid-cols-[5fr,7fr] items-center gap-8 place-items-center">
          <div className=" w-full">
            <div className="form__main">
              <h2 className="head__two py-5"> Course Enrollment </h2>

              <div className="grid md:grid-cols-2 lg:grid-cols-1 gap-3 ">
                <span className="p-float-label">
                  <InputText
                    id="fullName"
                    name="fullName"
                    value={values.fullName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full"
                  />
                  <label htmlFor="email">Full Name</label>
                </span>
                <span className="p-float-label">
                  <InputText
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full"
                  />
                  <label htmlFor="email">Email</label>
                </span>
                {errors.email && (
                  <small className=" text-red-500">{errors.email}</small>
                )}
                <div className="grid md:grid-cols-2 gap-2 items-center">
                  <div className="mt-2">
                    <span className="p-float-label">
                      <InputNumber
                        id="phone"
                        name="phone"
                        value={values.phone}
                        onValueChange={handleChange}
                        onBlur={handleBlur}
                        useGrouping={false} 
                        className="w-full"
                      />
                      <label htmlFor="phone">Phone Number</label>
                    </span>
                    {errors.phone && (
                      <small className=" text-red-500">{errors.phone}</small>
                    )}
                  </div>

                  <fieldset className="border rounded-md p-2">
                    <legend className="text-xs text-gray-500">Country</legend>
                    <p>{country}</p>
                  </fieldset>
                </div>
                <div className="">
                  <span className="p-float-label">
                    <Dropdown
                      name="course"
                      value={values.course}
                      options={courses}
                      optionLabel="courseTitle"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="phone">Select a Course</label>
                  </span>
                  {errors.course && (
                    <small className=" text-red-500">{errors.course}</small>
                  )}
                </div>
                <div className="">
                  {values?.course !== null ? (
                    <fieldset className="border rounded-md p-2">
                      <legend className="text-xs text-gray-500">
                        Age Range
                      </legend>
                      <p>{values?.course?.age}</p>
                    </fieldset>
                  ) : (
                    <fieldset className="border rounded-md p-2 !h-[60px]">
                      <legend className="text-xs text-gray-500">
                        Age Range
                      </legend>
                      <p></p>
                    </fieldset>
                  )}
                </div>
                <div className="">
                  <div className="p-float-label">
                    <InputText
                      type="text"
                      name="student"
                      placeholder="Type the name of student..."
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="w-full"
                    />
                    <label htmlFor=""> Student Name </label>
                  </div>
                  {errors.student && (
                    <small className=" text-red-500">{errors.student}</small>
                  )}
                </div>
                <div className="">
                  <div className="p-float-label">
                    <Calendar
                      name="date"
                      value={values.date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!w-full"
                      minDate={moment().subtract(17, "year").toDate()}
                      maxDate={moment().subtract(5, "year").toDate()}
                    />
                    <label htmlFor=""> Date of Birth </label>
                  </div>
                  {errors.date && (
                    <small className=" text-red-500">{errors.date}</small>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className=" h-full w-full bg-enrollment rounded-2xl ">
            <div className="main grid gap-4 md:mt-[50px] mt-[10px] lg:h-[400px] lg:overflow-y-scroll  ">
              <h4>Payment summary</h4>
              <div className="">
                <div className="text-3xl lg:text-5xl bold">
                  {country !== "Nigeria"
                    ? formatCurrency(course?.internationalPrice ?? 0, "USD")
                    : formatCurrency(course?.localPrice ?? 0, "NGN")}
                </div>
                <h4>TOTAL</h4>
              </div>
              <div className="grid gap-3">
                <form className="md:flex gap-4 ">
                  <div className="flex gap-2">
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId="coupon"
                        name="coupon"
                        onChange={handleChange}
                        checked={values.coupon}
                      />
                      <label htmlFor="coupon" className="ml-2">
                        Use coupon
                      </label>
                    </div>
                  </div>

                  {auth && auth.length > 0 && values.course._id ? (
                    <div className="md:flex gap-2">
                      <p>OR</p>
                      <div className="flex align-items-center">
                        <Checkbox
                          inputId="referralPoint"
                          name="referralPoint"
                          onChange={handleChange}
                          checked={values.referralPoint}
                        />
                        <label htmlFor="referralPoint" className="ml-2">
                          Use Referral Points
                        </label>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </form>
                {values.coupon ? (
                  <div className="">
                    <p>Enter coupon code</p>
                    <div className="w-full flex items-center gap-4">
                      <input
                        type="text"
                        name="couponData"
                        id="couponData"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="box  w-1/2"
                      />
                      <button
                        className="black_btn text-[var(--white)] w-fit flex items-center gap-1"
                        disabled={
                          !values.couponData || promoLoading || errors.course
                        }
                        onClick={usePromo}
                      >
                        {promoLoading ? (
                          <i className="pi pi-spin pi-spinner"></i>
                        ) : (
                          ""
                        )}
                        Apply coupon
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className=" md:w-[80%] gap-4 py-5">
                {country !== "Nigeria" ? (
                  <>
                    {!isValid ? (
                      <button
                        className="black_btn text-[var(--white)] w-fit col-span-2 flex items-center gap-2"
                        disabled
                      >
                        {" "}
                        Pay with Paypal
                      </button>
                    ) : (
                      <PayPalButton
                        className="!w-full"
                        createOrder={(data, actions) =>
                          createOrder(data, actions)
                        }
                        onApprove={(data, actions) => onApprove(data, actions)}
                      />
                    )}
                  </>
                ) : (
                  <div className="flex flex-col md:flex-row md:items-center md:flex-wrap gap-3">
                    <button
                      className="black_btn text-[var(--white)] w-fit flex items-center gap-2"
                      disabled={!isValid || loading}
                      onClick={openModal}
                    >
                      {loading ? <i className="pi pi-spin pi-spinner"></i> : ""}
                      Pay Via Transfer
                    </button>
                    {/* <button
                      className="black_btn text-[var(--white)] w-fit flex items-center gap-2"
                      disabled={!isValid || loading}
                      onClick={makePayment}
                    >
                      {loading ? <i className="pi pi-spin pi-spinner"></i> : ""}
                      Pay with Flutterwave
                    </button> */}
                    <button
                      className="black_btn text-[var(--white)] w-fit flex items-center gap-2"
                      disabled={!isValid || gemsPayloading}
                      onClick={payWithGems}
                    >
                      {gemsPayloading ? (
                        <i className="pi pi-spin pi-spinner"></i>
                      ) : (
                        ""
                      )}
                      Pay with Gems Pay
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {paymentModal && (
        <>
          <iframe
            src={url}
            className="fixed h-screen w-screen top-0 left-0 z-50"
          ></iframe>
        </>
      )}
      {transfer && (
        <>
          <div className="overlay" onClick={openModal}></div>
          <div
            className="bg-enrollment bg-cover fixed h-fit w-[90vw] lg:w-[50vw] bg-white top-1/2 left-1/2 z-50 rounded-2xl -translate-x-1/2 -translate-y-1/2 p-10"
     
          >
            <div className="">

            <div className="pb-10 text-lg text-red-500">
              Note: Please make transfers to the bank details below and share the evidence of payment to training@chariscodingclub.com
            </div>

              <div className=" backdrop-blur-[200px] w-fit p-2 rounded-lg">
              <h4 className="text-xl py-3"> Total Amount</h4>
                <div className="text-3xl lg:text-5xl bold">
                  {formatCurrency(course?.localPrice ?? 0, "NGN")}
                </div>
                <div className="text-xl lg:text-2xl pt-3">
                  Account Number: <span className="bold">0564576985 </span>{" "}
                </div>
                <div className="text-xl lg:text-2xl pt-1">
                  Account Name: <span className="bold">Magna Charis Nigeria Limited</span>
                </div>
                <div className="text-xl lg:text-2xl pt-1 ">
                  Bank: <span className="bold">GTBANK</span>{" "}
                </div>
              </div>

            </div>
            <div
              className="absolute z-[1000] p-2 bg-white -top-6 -right-6 h-[35px] w-[35px] rounded-full flex items-center justify-center cursor-pointer"
              onClick={openModal}
            >
              X
            </div>
          </div>
        </>
      )}
    </div>
  );
}
