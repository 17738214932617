import React from 'react';
import arrow_img from '../assets/background-img/arrow.png';
import pattern from "../assets/patterns/about-pattern.svg";
import pattern2 from "../assets/patterns/about-pattern2.svg";
import { Link } from 'react-router-dom';

export default function ErrorPage() {
  return (
    <div className='h-[90vh] mt-[80px]  w-screen'>
      <div className="main h-full flex flex-col items-center justify-center gap-3 ">
        <div className="h-[130px]">
          <img src={arrow_img} alt="" className='h-full object-contain'/>
        </div>
        <div className="absolute left-0 bottom-[10%]">
          <img src={pattern} alt="" className="w-[70%]" />
        </div>
        <div className="absolute right-0 top-[18%] md:top-[50%]">
          <img src={pattern2} alt="" className="w-[70%]"/>
        </div>
        <div className=" text-center grid gap-2">
          <div className='text-5xl md:text-8xl lg:text-[13rem] bold leading-[1]'>Whooops!!.</div>
          <h3 className=' lg:text-2xl '>The page doesn't exist. Let's get you back home.</h3>
          <Link to='/' className="pri_btn w-fit mx-auto">Back to Home</Link>
        </div>
      </div>
    </div>
  )
}
