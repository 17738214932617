import { Navigate, Outlet } from "react-router-dom";
import ScrollToTop from "../pages/scrollToTop";
import Header from "../components/header/Header";
import { useRecoilValue } from "recoil";
import { authState } from "../atom/authAtom";

export default function GuestLayout() {
  const auth = useRecoilValue(authState);

  return (
    <div>
      <Header />
      {auth && auth.length > 0 ? (
        <Navigate to="/" />
      ) : (
        <>
          <Outlet />
          <ScrollToTop />
        </>
      )}
    </div>
  );
}
