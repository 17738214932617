import React, { useState } from "react";
import projects from "../../data/projects.json";
import projectPath from "../../assets/patterns/project-path.svg";

export default function Projects() {
  const [currentLink, setCurrentLink] = useState(projects[0].link);
  const [tab, setTab] = useState(projects[0].text);
  const [instruction, setInstruction] = useState(projects[0].instruction);
  const handleClick = (data) => {
    setCurrentLink(data.link);
    setTab(data.text);
    setInstruction(data.instruction);
  };
  return (
    <div className="mt-[100px] w-screen h-full lg:h-[80vh]">
      <div className="main  grid  md:grid-cols-2 items-center gap-10 h-full place-content-center">
        <div className="grid gap-6 relative">
          <h2 className="lg:w-[60%]">Some of our Students Projects </h2>
          <div className="h-[40px] absolute right-[5%] lg:top-[10%]">
            <img src={projectPath} alt="" className=" h-full object-contain" />
          </div>
          <div className="grid gap-3 lg:w-[85%] ">
            {projects.map((res, key) => (
              <div
                onClick={() => handleClick(res)}
                className={tab === res.text ? "bright-yellow" : "faded-yellow"}
                key={key}
              >
                <p> {res.text}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="yellow w-full lg:h-[80vh] ">
          <div className="flex gap-4">
            <h4 className="semiBold">Instruction:</h4>
            <p>{instruction}</p>
          </div>

          <div className="w-full mt-6">
            <div className="iframe w-full">
              <iframe
                src={currentLink}
                frameBorder="0"
                scrolling="no"
                className="w-full h-[250px] lg:h-[450px]"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
